import $ from 'jquery'
import 'magnific-popup';

function initPopup(e) {
  $('.team-members').magnificPopup({
    delegate: 'a',
    type: 'inline',
    midClick: true,
    preloader: false,
    mainClass: 'team-popup'
  });
}

export default function initMagnificPopup(){
  $(document).ready(initPopup)
}
