import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function animateFirstElem(elem) {
  gsap.to(elem, {
    duration: 0.8,
    y: 0,
    autoAlpha: 1,
    ease: "power2.inOut",
    stagger: 0.5,
    delay: 0.8,
    overwrite: 'auto'
  })
}

function animateHomeHero() {

  let heroTL = gsap.timeline({delay: 0,onComplete: fadeIns});

  heroTL
  .addLabel("hero-start")
  .to(".js-home-hero", {duration: 1,autoAlpha: 1,ease: "power2.inOut"},"hero-start")
  .to(".js-home-hero > .wrapper", {autoAlpha: 1, duration: 0.9, ease: "power2.inOut"},"hero-start")
  .from(".js-home-hero-bg", {height: "10vw", duration: 0.8, ease: "power1.inOut"},"hero-start")
  .from(".js-home-hero-bg-one", {y: 200, duration: 0.9,  ease: "power1.inOut"},"hero-start")
  .from(".js-home-hero-bg-two", {y: 100, duration: 0.9,  ease: "power1.inOut"},"hero-start")
  .to(".js-home-hero-sun", {autoAlpha: 1, top: "-35%", duration: 1.5,delay: 0.3, ease: "power1.0ut"},"hero-start")
  .fromTo(".js-home-hero-featured",{autoAlpha: 0}, {duration: 0.6, autoAlpha: 1, ease: "power1.inOut",stagger: 0.1,delay: 0.2}, "-=1");

  sessionStorage.setItem("set", "set");
}

function showHomeHero() {
  let showheroTL = gsap.timeline({delay: 0,onComplete: fadeIns});

  showheroTL
  .set(".js-home-hero > .wrapper", {height: "auto"})
  .set(".js-home-hero-bg", {height: "20vw"})
  .set(".js-home-hero-sun", {autoAlpha: 1, top: "-35%"})
  .set(".js-home-hero-featured",{y: 0, autoAlpha: 1})
  .to(".js-home-hero", {duration: 0.5,autoAlpha: 1,ease: "power2.inOut"});
}

function fadeIns() {
  gsap.utils.toArray(".js-fadeinFirstElem").forEach(function(elem) {
    hide(elem);
    ScrollTrigger.create({
      trigger: elem,
      onEnter: function() { animateFirstElem(elem) },
    });
  });
  gsap.utils.toArray(".js-fadein").forEach(function(elem) {
    hide(elem);
    const anim = gsap.to(elem, {duration: 0.6, autoAlpha: 1, y: 0, delay: 0.3, paused: true});
    ScrollTrigger.create({
      trigger: elem,
      end: "top 100px",
      once: true,
      onEnter: self => {
        // If it's scrolled past, set the state
        // If it's scrolled to, play it
        self.progress === 1 ? anim.progress(1) : anim.play()
      },
    });
  });
  gsap.utils.toArray(".js-fadeinFast").forEach(function (elem) {
    hide(elem);
    const anim = gsap.to(elem, { duration: .3, autoAlpha: 1, y: 0, delay: 0.1, paused: true });
    ScrollTrigger.create({
      trigger: elem,
      end: "top 100px",
      once: true,
      onEnter: self => {
        // If it's scrolled past, set the state
        // If it's scrolled to, play it
        self.progress === 1 ? anim.progress(1) : anim.play()
      },
    });
  });
  gsap.utils.toArray(".js-fadeinStagger").forEach((elem, i) => {
    gsap.set(elem, {opacity:0})
    gsap.from(elem, {
      y: 10,
      ease: "power2.inOut",
      scrollTrigger: {
        trigger: elem,
        start: "top 100%"
      }
    });
  });
  ScrollTrigger.batch(".js-fadeinStagger", {
    onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, ease: "power2.inOut",stagger: {each: 0.15, grid: [1, 3]}, overwrite: true}),
    // onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 10, overwrite: true})
  });
  ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".js-fadeinStagger", {y: 0}),{passive:true});
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0, y: 5})
}

export default function initGreenSock(){
  document.addEventListener("DOMContentLoaded", function() {
    gsap.registerPlugin(ScrollTrigger);
  
    if($('body.home').length  == 0) {
      fadeIns();
    } else {
  
      var width = $(document).width();
  
      if(width > 700) {
        if (!(sessionStorage.getItem('set') === 'set')) {
          animateHomeHero();
        } else {
          showHomeHero();
        }
      } else {
        showHomeHero();
      }
  
      $(window).on("resize", function(event) {
        showHomeHero();
      });
    }
  },{passive:true});
}
