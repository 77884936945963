import $ from 'jquery'

function openBanner() {
    
    if(!(sessionStorage.getItem('banner') === 'set') && $('.js-header').hasClass('has-banner')) {  
      var bannerHeight = $('#header-banner').innerHeight();
      var mainElement = $('.main');
      var newElement = $( '<div class="banner-spacer"></div>' );
      mainElement.prepend(newElement);
      newElement.css('max-height', bannerHeight);
    } else {
      $('.js-header').removeClass('has-banner');
    }
}

function closeBanner(event) {
    event.preventDefault();
    $('.js-header').removeClass('has-banner');
    $('.main > div.banner-spacer').css('max-height', '0');
    sessionStorage.setItem("banner", "set");
}

export default function initBanner(){
    $(document).ready(openBanner)
    $(document).on('click', '.js-close-banner', closeBanner )
}