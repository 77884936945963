import $ from 'jquery'

function initNavSingleWork() {
  if($('body').hasClass('single-work')) {
    $('.nav--main > .nav__list > .menu-item:first-child').addClass('current-menu-item');
  }
}

export default function activeNavSingleWork(){
  $(document).ready(initNavSingleWork)
}
