import $ from 'jquery'

function initMainSliders() {
  $('.js-testimonials-slider').slick({
    dots: false,
    infinite: true,
    arrows: true,
    fade: true,
    cssEase: 'ease-in-out',
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 8000,
    assesibilitiy: true,
    adaptiveHeight: true
  });
  $('.js-portfolio-slider').slick({
    dots: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplay: true,
    assesibilitiy: true,
    autoplaySpeed: 5000
  });
  $('.js-work-slider').slick({
    dots: false,
    arrows: true,
    slidesToShow: 3,
    pauseOnHover: true,
    autoplay: true,
    infinite: true,
    assesibilitiy: true,
    draggable: true,
    autoplaySpeed: 5000,
    centerMode: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
}

function initWorkCatSlider() {
  const slider = $('.work-cat-slider');

  slider.each( function() {

    if (slider.length) {
      const settings = {
        dots: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        infinite: false,
        appendArrows: $(this).prev('.wrapper').find('.arrows-container'),
        responsive: [
          {
            breakpoint: 960,
            settings: "unslick"
          }
        ]
      }

      $(this).slick(settings);
      
      /* $(window).on('resize', function() {
        slider.each( function() {
          if (!$(this).hasClass('slick-initialized')) {
            return $(this).slick(settings);
          }
        });
      }); */
    }

  });
}

export default function initSliders() {
  $(document).ready(initMainSliders)
  $(document).ready(initWorkCatSlider)
}
