/*
  * For performance improvements we're calling contact form in iframe on the homepage
  * and we need to pass the redirect url to the parent window to be able to refresh the parent page
 */

export default function initIframeForms() {
  window.addEventListener('message', e => {
    const {identifier} = e.data

    if (identifier === 'getRedirectUrl') {
      const {url} = e.data

      // Redirect to the URL
      window.location.href = url
    }

    if (identifier === 'confirmationMessage') {
      const iframe = document.querySelector('.contact-cta__iframe')
      const {messageHeight} = e.data
      if (iframe) iframe.style.height = `${messageHeight}px`
    }

    // ...
  },false);
}
