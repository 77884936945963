$(document).ready(function (){
$(".contact-phone-required input").addClass("phone-input-required");
$(".contact-email input").addClass("email-input");
$("[data-scroll-to]").click(function() {
  var $this = $(this),
      $toElement      = $this.attr('data-scroll-to'),
      $focusElement   = $this.attr('data-scroll-focus'),
      $offset         = $this.attr('data-scroll-offset') * 1 || 0,
      $speed          = $this.attr('data-scroll-speed') * 1 || 500;

  var emailMe = $(".contact-options input").eq(0);
  var callMe = $(".contact-options input").eq(1);
  if($focusElement === ".phone-input-required"){
    $(callMe).click();
  }
  else{
    $(emailMe).click();
  }

  $('html, body').animate({
    scrollTop: $($toElement).offset().top + $offset
  }, $speed);
  if ($focusElement) $($focusElement).focus();
  });

  // LANDING PAGE - CONTACT FORM
  $("#gform_fields_27").append("<div class='contact-form-section contact-form-section-left'></div>");
  $("#gform_fields_27").append("<div class='contact-form-section contact-form-section-right'></div>");
  $("#gform_fields_27 li").slice(0,5).addClass("form-left-fields");
  $("#gform_fields_27 li").slice(5,8).addClass("form-right-fields");

  $(".contact-form-section-left").append($(".form-left-fields"));
  $(".contact-form-section-right").append($(".form-right-fields"));
  $(".contact-form-section-right").append($(".gform_footer:first"));

});
